export default ({ app }, inject) => {

    inject('event', {
        newsletterSubscribed: () => {
            app.$gtm.push({
                event: 'newsletterSubscribed'
            });
        },
        loanWizardSubmitted: () => {
            app.$gtm.push({
                event: 'loanWizardSubmitted'
            });
        }
    });
};